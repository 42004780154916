

//这里是默认项目的动态路由配置文件

//mi si mi 英文版



export const projectDefault = [
    {
        path:'/',
        redirect:'/index'
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('../project/projectDefault/views/RegisterView.vue')
    },
    {
      path: '/forgetPassword',
      name: 'forgetPassword',
      component: () => import('../project/projectDefault/views/ForgetPasswordView.vue')
    },
    {
        path:'/index',
        name:'index',
        component:()=>import('../project/projectDefault/views/index/IndexView.vue'),
        children:[
            {
                path:'',
                name:'home1',
                component:()=>import('../project/projectDefault/views/index/home/HomeView.vue')
            },
            {
                path:'product',
                name:'product',
                component:()=>import('../project/projectDefault/views/index/product/ProductView.vue')
            },
            {
                path:'detail',
                name:'detail',
                component:()=>import('../project/projectDefault/views/index/detail/DetailView.vue')
            },
            {
                path:'contactUs',
                name:'contactUs',
                component:()=>import('../project/projectDefault/views/index/contactUs/ContactUsView.vue')
            },
            {
                path:'shopCart',
                name:'shopCart',
                component:() => import('../project/projectDefault/views/index/shopCart/ShopCartView.vue')
            },
            {
                path: 'payment',
                name: 'payment',
                component:() => import('../project/projectDefault/views/index/pay/PayView.vue')
            },
            {
                path: 'favorites',
                name: 'favorites',
                component:() => import('../project/projectDefault/views/index/favorites/FavoritesView.vue')
            }
        ]
    },


    {
        path: '/aboutUs',
        name:'aboutUs',
        component:()=>import('@/project/projectDefault/views/aboutUs/AboutUsView.vue'),
        children:[
            {
                path: '',
                name: 'home',
                component:()=>import('@/project/projectDefault/views/aboutUs/aboutUsHome/aboutUsHomeView.vue')
            },
            {
                path:'/aboutUs/operate',
                name:'operate',
                component:()=>import('../project/projectDefault/views/aboutUs/OperatingGuide/OperatingGuideView.vue')
            }
        ]
    },


//     会员信息
    {
        path: '/personal',
        name: 'personal',
        component: () => import('../project/projectDefault/views/personal/personalView.vue'),
        children: [
            {
                path: '',
                name: 'personal',
                component:() => import('../project/projectDefault/views/personal/home/personalHomeView.vue')
            },
            {
                path: '/browsingHistory',
                name: 'browsingHistory',
                component:() =>import('../project/projectDefault/views/personal/History/browsingView.vue')
            },
            {
                path: '/purchase',
                name: 'purchase',
                component:() => import('../project/projectDefault/views/personal/History/purchaseView.vue')
            },
            {
                path: '/personalInformation',
                name: 'personalInformation',
                component:() => import('../project/projectDefault/views/personal/personalInformation/personalInformationView.vue')
            },
            {
                path: 'becomeSeller',
                name: 'becomeSeller',
                component:() => import('../project/projectDefault/views/personal/becomeSeller/becomeSellerView.vue')
            },
            {
                path: '/messageCenter',
                name: 'messageCenter',
                component:() => import('../project/projectDefault/views/personal/messageCenter/messageCenterView.vue')
            }
        ]
    }

]




























